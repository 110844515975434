@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400&display=swap");

:root {
  --primary-color: #373737;
  --secondary-color: #42c7fd;
  --paragraph-font: 14px;
  --heading-font: 1.2rem;
  --default-line-height: 1.2rem;
}

* {
  font-family: "Roboto", sans-serif !important;
}

#sidebar-tab svg {
  color: var(--primary-color);
}

.main-body :is(h3, h4, h5, h6) {
  font-size: 1.2rem !important;
  line-height: 1.5;
  color: var(--primary-color);
}

.main-body :is(p, label) {
  font-size: 14px;
  line-height: 1.5;
  color: var(--primary-color);
}
.contact_detail span {
  font-size: 12px !important;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.custom-icons {
  display: inline-block;
  height: 1em;
  width: 1em;
  visibility: inherit;
  fill: currentcolor;
  box-sizing: content-box;
  stroke: currentcolor;
}

.description-content * {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
}

.lds-ripple div {
  position: absolute;
  border: 4px solid #000;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}

.divider {
  animation: line-animation 1.2s;
}
.bottom_navbar {
  display: none;
}
.sciever_logo {
  display: none;
}
.img_logo {
  width: 100px;
  max-width: 75px;
}
.footer-device {
  display: none;
}
.partners_body {
  padding: 25px;
}
#work-gallery {
  padding: 0 32px;
}
.not-found-text {
  line-height: 1.6rem;
  font-size: 1.1rem;
}
.not-found-img {
  width: 300px;
}
/* .logo {
  padding: 25px 25px 0 25px;
} */

.clamp-line > p > span {
  -webkit-line-clamp: 3;
  overflow: hidden;
  box-sizing: border-box;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

@keyframes line-animation {
  from {
    height: 0;
  }
  to {
    height: 50%;
  }
}

.divider-sm {
  animation: line-animation-sm 1.2s;
}

@keyframes line-animation-sm {
  from {
    height: 0;
  }
  to {
    height: 25%;
  }
}

@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  4.9% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  5% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.swiper-pagination-bullet-active {
  background-color: var(--secondary-color) !important;
  transition: 0.2s;
}

.App {
  flex-direction: row;
}

.content {
  height: 100vh;
}

.banner-section {
  /* padding: 10px; */
}

.map-section {
  height: 96%;
}

.banner-section .swiper {
  max-width: 60vw;
  height: 100%;
}

.banner-img {
  height: 100%;
}

.banner-description {
  top: 20%;
  left: 20px;
}

.testimonial {
  padding: 10px 25px;
}

.partners {
  padding: 0px 25px;
  box-sizing: border-box;
}

#contact-form .MuiInputBase-root {
  background-color: rgb(188 188 188 / 10%);
}

#contact-form .MuiInputBase-root::before {
  border: unset;
}

#modal-body {
  max-width: 90%;
}

#modal-body .modal-description .desc-1 {
  width: 60%;
}
#modal-body .modal-description .desc-2 {
  width: 40%;
  padding: 0px 20px;
  overflow-y: auto;
  overflow-x: hidden;
  margin-top: 25px;
}

.modal-content {
  padding: 0 60px;
}

#gallery {
  width: 80%;
  grid-template-columns: repeat(auto-fit, minmax(100px, 150px));
}

#main-layout {
  height: calc(100% + 24px);
}

.partners,
.testimonial {
  width: 52vw;
}

.banner-section {
  height: 100%;
}

.banner-description > p {
  font-size: 1.95rem;
}

.solutions {
  padding: 20px 32px;
}
.solutions_content {
  padding-top: 8px;
  padding-inline: 32px;
}
.work_small {
  padding: 20px 30px 0 30px;
}
.about-us {
  padding: 25px 25px 0 25px;
}
.contact_small {
  padding: 20px 32px;
}
.contact_body {
  padding: 0px 32px;
}
.contact_detail {
  padding: 0px 32px;
}
.career_main {
  padding: 20px 30px;
}
.career_body {
  padding-bottom: 20px;
}
.company_main {
  padding: 0 25px;
}
.company_body {
  padding: 20px 0px;
}

@media (min-width: 1200px) {
  .sidebar-desktop {
    display: block;
  }

  .sidebar-tablet {
    display: none;
  }
}

@media only screen and (max-width: 1199px) {
  .footer-device {
    display: block;
  }
  .sciever_logo {
    display: block;
  }
  .logo {
    padding: 30px 15px 0 30px;
  }
  .bottom_navbar {
    display: block;
  }
  #main-layout {
    flex-direction: column-reverse;
    height: unset;
    padding-bottom: 70px;
  }

  #main-body {
    padding-top: 0px;
  }

  .partners,
  .testimonial {
    width: 100vw;
  }

  .banner-section,
  .map-section {
    height: 240px;
  }

  .map-section {
    width: 94%;
    margin: 24px;
  }

  .banner-section .swiper {
    max-width: 100vw;
    margin-right: 0;
  }

  .banner-description {
    top: 50%;
    transform: translateY(-50%);
    right: 20px;
    left: 0;
  }

  .banner-description * {
    text-align: right;
  }

  .banner-description button {
    float: right;
  }

  #gallery {
    width: 90%;
  }

  #sidebar-tab {
    min-width: 230px;
  }

  .partners {
    padding: 30px;
  }

  .sidebar-desktop {
    display: none;
  }

  .testimonial {
    padding: 30px;
    margin: 0 auto;
    box-sizing: border-box;
  }

  .banner-section {
    padding: 0;
    padding-top: 10px;
  }

  .banner-section .swiper .banner-img {
    width: 60%;
    aspect-ratio: 16/9;
  }

  .divider {
    display: none;
  }

  .divider-sm {
    display: none;
  }

  .sidebar-tablet {
    display: flex;
  }

  .App {
    flex-direction: column;
  }

  .content {
    height: calc(100vh - 65px);
  }

  .about-us {
    padding: 30px;
  }

  .footer {
    padding: 15px 0 10px 0;
  }
}

/* @media (max-width: 574px) {
  .sidebar-desktop {
    display: none;
  }

  .sidebar-tablet {
    display: none;
  }
} */

@media (max-width: 768px) {
  .solutions,
  .solutions_content,
  .about-us,
  .partners,
  .testimonial {
    padding: 15px;
  }
  .solutions {
    padding-bottom: 0px;
  }
  .map-section {
    margin-bottom: 0px;
  }

  .work_small {
    padding: 15px 15px 0 15px;
  }
  .contact_small {
    padding: 15px;
  }
  .contact_body {
    padding: 0px 15px;
  }
  .contact_detail {
    padding: 0px 15px;
  }
  .career_main {
    padding: 15px;
  }
  .career_body {
    padding-bottom: 15px;
  }
  .company_main {
    padding: 0 15px;
  }
  .company_body {
    padding: 15px 0px;
  }

  .bottom_navbar {
    display: block;
  }
  .partners_body {
    padding: 15px;
  }
  .logo {
    padding: 15px 15px 0 15px;
  }
  .img_logo {
    width: 60px;
    max-width: 60px;
  }
  #modal-body {
    height: 95% !important;
  }

  #modal-body .modal-description {
    flex-direction: column;
  }

  #modal-body .modal-description .desc-1 {
    width: 100%;
    height: 60%;
    margin: 0 auto;
  }

  #modal-body .modal-description .desc-1 img {
    width: auto !important;
  }
  #modal-body .modal-description .desc-2 {
    width: 100%;
    height: 40%;
    padding: 0px;
    margin-top: 0px;
  }

  .banner-section .swiper .banner-img {
    width: 90%;
  }

  #gallery {
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  }

  .testimonial {
    width: 100vw;
  }
  .solutions_home {
    display: "flex";
    justify-content: space-around;
    /* padding: 0px 25px 0px 25px; */
    align-items: baseline;
  }
  #work-gallery {
    padding: 0 15px;
  }
  .not-found-text {
    line-height: 1.6rem;
    font-size: 0.9rem;
  }
  .not-found-img {
    width: 200px;
  }
}

@media (max-width: 580px) {
  .modal-content {
    padding: 0 25px;
  }
  .banner-description > p {
    font-size: 1.8rem;
  }
  .solutions_home {
    display: "flex";
    justify-content: space-around;
    /* padding: 0px 25px 0px 25px; */
    align-items: baseline;
    padding-bottom: 10px;
  }
}
